var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Модель",
            visible: !!_vm.modelToEdit,
            "destroy-on-close": "",
            width: "80%",
          },
          on: {
            close: function ($event) {
              _vm.modelToEdit = undefined
            },
          },
        },
        [
          _vm.modelToEdit
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("Название")]),
                  _c("el-input", {
                    staticStyle: { "margin-top": "20px" },
                    model: {
                      value: _vm.modelToEdit.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelToEdit, "name", $$v)
                      },
                      expression: "modelToEdit.name",
                    },
                  }),
                  _c("p", { staticStyle: { "margin-top": "20px" } }, [
                    _vm._v("Коды"),
                  ]),
                  _vm._l(_vm.companiesArray, function (code) {
                    return _c(
                      "div",
                      { key: code, staticStyle: { "margin-top": "20px" } },
                      [
                        _c("p", [_vm._v(_vm._s(code))]),
                        _c("el-input", {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { placeholder: "Введите внутреннее имя" },
                          model: {
                            value: _vm.modelToEdit.code[code].name,
                            callback: function ($$v) {
                              _vm.$set(_vm.modelToEdit.code[code], "name", $$v)
                            },
                            expression: "modelToEdit.code[code].name",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { placeholder: "Введите код" },
                          model: {
                            value: _vm.modelToEdit.code[code].code,
                            callback: function ($$v) {
                              _vm.$set(_vm.modelToEdit.code[code], "code", $$v)
                            },
                            expression: "modelToEdit.code[code].code",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px" },
                      on: { click: _vm.updateModel },
                    },
                    [_vm._v("Сохранить")]
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Марка",
            visible: !!_vm.brandToEdit,
            "destroy-on-close": "",
            width: "30%",
          },
          on: {
            close: function ($event) {
              _vm.brandToEdit = undefined
            },
          },
        },
        [
          _vm.brandToEdit
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("Название")]),
                  _c("el-input", {
                    staticStyle: { "margin-top": "20px" },
                    model: {
                      value: _vm.brandToEdit.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.brandToEdit, "name", $$v)
                      },
                      expression: "brandToEdit.name",
                    },
                  }),
                  _c("p", { staticStyle: { "margin-top": "20px" } }, [
                    _vm._v("Коды"),
                  ]),
                  _vm._l(_vm.companiesArray, function (code) {
                    return _c(
                      "div",
                      { key: code, staticStyle: { "margin-top": "20px" } },
                      [
                        _c("p", [_vm._v(_vm._s(code))]),
                        _c("el-input", {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { placeholder: "Введите внутреннее имя" },
                          model: {
                            value: _vm.brandToEdit.code[code].name,
                            callback: function ($$v) {
                              _vm.$set(_vm.brandToEdit.code[code], "name", $$v)
                            },
                            expression: "brandToEdit.code[code].name",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { placeholder: "Введите код" },
                          model: {
                            value: _vm.brandToEdit.code[code].code,
                            callback: function ($$v) {
                              _vm.$set(_vm.brandToEdit.code[code], "code", $$v)
                            },
                            expression: "brandToEdit.code[code].code",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px" },
                      on: { click: _vm.updateBrand },
                    },
                    [_vm._v("Сохранить")]
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-container",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { direction: "vertical" },
        },
        [
          _c(
            "div",
            { staticClass: "menu-buttons" },
            [
              _c("el-input", {
                attrs: { placeholder: "Поиск..." },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.models } },
            [
              _c("el-table-column", {
                attrs: { prop: "_brand.name", label: "Марка" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "Название" },
              }),
              _c("el-table-column", { attrs: { prop: "type", label: "Тип" } }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "column-buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editModel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Изменить модель")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editBrand(scope.row._brand)
                                  },
                                },
                              },
                              [_vm._v("Изменить марку")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.stats } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "Марка и СК" },
              }),
              _c("el-table-column", {
                attrs: { prop: "count", label: "Кол-во" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }