<template>
  <el-main>
        <el-dialog title="Модель" :visible="!!modelToEdit" @close="modelToEdit = undefined" destroy-on-close width="80%">
            <div v-if="modelToEdit">
                <p>Название</p>
                <el-input style="margin-top: 20px;" v-model="modelToEdit.name" />

                <p style="margin-top: 20px;">Коды</p>

                <div v-for="code in companiesArray" :key="code" style="margin-top: 20px;">
                    <p>{{ code }}</p>
                    <el-input style="margin-top: 20px;" v-model="modelToEdit.code[code].name" placeholder="Введите внутреннее имя" />
                    <el-input style="margin-top: 20px;" v-model="modelToEdit.code[code].code" placeholder="Введите код" />
                </div>

                <el-button style="margin-top: 20px;" @click="updateModel">Сохранить</el-button>
            </div>
        </el-dialog>
         <el-dialog title="Марка" :visible="!!brandToEdit" @close="brandToEdit = undefined" destroy-on-close width="30%">
            <div v-if="brandToEdit">
                <p>Название</p>
                <el-input style="margin-top: 20px;" v-model="brandToEdit.name" />

                <p style="margin-top: 20px;">Коды</p>

                <div v-for="code in companiesArray" :key="code" style="margin-top: 20px;">
                    <p>{{ code }}</p>
                    <el-input style="margin-top: 20px;" v-model="brandToEdit.code[code].name" placeholder="Введите внутреннее имя" />
                    <el-input style="margin-top: 20px;" v-model="brandToEdit.code[code].code" placeholder="Введите код" />
                </div>

                <el-button style="margin-top: 20px;" @click="updateBrand">Сохранить</el-button>
            </div>
        </el-dialog>
        <el-container direction="vertical" style="margin-top: 20px;">
            <div class="menu-buttons">
               <el-input v-model="name" placeholder="Поиск..." />
            </div>
            <el-table :data="models">
                <el-table-column prop="_brand.name" label="Марка"></el-table-column>
                <el-table-column prop="name" label="Название"> </el-table-column>
                <el-table-column prop="type" label="Тип"></el-table-column>

                <el-table-column>
                    <template slot-scope="scope">
                        <div class="column-buttons">
                            <el-button size="mini" @click="editModel(scope.row)">Изменить модель</el-button>
                            <el-button size="mini" @click="editBrand(scope.row._brand)">Изменить марку</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

             <el-table :data="stats">
                <el-table-column prop="name" label="Марка и СК"></el-table-column>
                <el-table-column prop="count" label="Кол-во"> </el-table-column>
            </el-table>
        </el-container>
    </el-main>
</template>

<script>
export default {
    data() {
        return {
            models: [],

            stats: {},

            name: "",

            modelToEdit: undefined,
            brandToEdit: undefined,

            companies: {
                INGOS: "INGOS",
                ALPHA: "ALPHA",
                RENESSANS: "RENESSANS",
                INTOUCH: "INTOUCH",
                RGS: "RGS",
                TINKOFF: "TINKOFF",
                SOGLASIE: "SOGLASIE",
                VSK: "VSK",
                MAKC: "MAKC",
                ABSOLUT: "ABSOLUT",
                VERNA: "VERNA",
            },
        };
    },

    computed: {
        companiesArray() {
            return Object.keys(this.companies);
        },
    },

    methods: {
        editModel(model) {
            for (const code of this.companiesArray) {
                if (!model.code[code]) {
                    model = { ...model, code: { ...model.code, [code]: {
                        company: code,
                        name: "",
                        code: "",
                    } } };
                }
            }

            this.modelToEdit = model;
        },
        editBrand(brand) {
            for (const code of this.companiesArray) {
                if (!brand.code[code]) {
                    brand = { ...brand, code: { ...brand.code, [code]: {
                        company: code,
                        name: "",
                        code: "",
                    } } };
                }
            }

            this.brandToEdit = brand;
        },
        async getModels() {
            try {
                const res = await this.$Api.Main.getModels(this.name);

                this.models = res;
            } catch (error) {
               alert(error.message || error);
            }
        },

        async getStats() {
            try {
                const res = await this.$Api.Main.getModelStats();

                this.stats = res;
            } catch (error) {
                 alert(error.message || error);
            }
        },

        async updateModel() {
            try {
               await this.$Api.Main.editModel(this.modelToEdit._id, this.modelToEdit);
               this.getModels();
            } catch (error) {
               alert(error.message || error);
            } finally {
                this.modelToEdit = undefined;
            }
        },

        async updateBrand() {
            try {
                await this.$Api.Main.editBrand(this.brandToEdit._id, this.brandToEdit);
                this.getModels();
            } catch (error) {
                alert(error.message || error);
            } finally {
                this.brandToEdit = undefined;
            }
        },
    },

    mounted() {
        this.getModels();
        this.getStats();
    },

    watch: {
        name() {
            this.getModels();
        },
    },
};
</script>

<style>

</style>